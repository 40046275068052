import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

import { BackIcon, CardIcon, ForwardIcon } from "../../utils/svgicons";

const Ourusers = () => {
  // const [activeIndex, setActiveIndex] = useState(2);

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 800,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          centerPadding: "30px",
        },
      },
    ],
  };
  // const data = [
  //   {
  //     id: 0,
  //     text: "A happy workforce leads to a happy work environment.",
  //     name: "Jaime, Spain",
  //     detail: "on the benefits of his experience",
  //   },
  //   {
  //     id: 1,
  //     text: "Changing my thoughts has allowed me to change my life.",
  //     name: "Davide, London",
  //     detail: "on using meditation to turn his life around",
  //   },
  //   {
  //     id: 2,
  //     text: "Headspace provides me with … a connection to myself, and a disconnection from negative thoughts, feelings, and sensations.",
  //     name: "Keri, UK",
  //     detail: "on finding her happy place",
  //   },
  //   {
  //     id: 3,
  //     text: "I came to learn that the storyline in my head … was holding me back.",
  //     name: "Peter, Belgium",
  //     detail: "on what he learned when sitting with himself",
  //   },
  //   {
  //     id: 4,
  //     text: "Meditation has brought more balance and tolerance to my life.",
  //     name: "Sarah, USA",
  //     detail: "on finding balance and tolerance in her daily life",
  //   },
  // ];

  // const handleBackward = () => {
  //   setActiveIndex((prevIndex) =>
  //     prevIndex === 0 ? data.length - 1 : prevIndex - 1
  //   );
  // };

  // const handleForward = () => {
  //   setActiveIndex((prevIndex) =>
  //     prevIndex === data.length - 1 ? 0 : prevIndex + 1
  //   );
  // };

  return (
    <div className="our-users-component mb-5" id={"reviews"}>
      <div className="content d-flex">
        <h1>
          What Our Users
          <span className="span-kansensus"> Say About Rolidex</span>
        </h1>
        <h3>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupt.
        </h3>
      </div>
      <div className="slider-container">
        <Slider
          ref={slider => {
            sliderRef = slider;
          }}
          {...settings}>
          <div className="slide">
            <div className="testimonial">
              <CardIcon />
              <p className="comment">
                "I came to learn that the storyline in my head ... was holding
                me back."
              </p>
              <h4 className="name">Peter, Belgium</h4>
              <p className="detail">
                on what he learned when sitting with himself
              </p>
            </div>
          </div>
          <div className="slide">
            <div className="testimonial">
              <CardIcon />
              <p className="comment">
                "Headspace provides me with ... a connection to myself, and a
                disconnection from negative thoughts, feelings, and sensations."
              </p>
              <h4 className="name">Keri, UK</h4>
              <p className="detail">on finding her happy place</p>
            </div>
          </div>
          <div className="slide">
            <div className="testimonial">
              <CardIcon />
              <p className="comment">
                "Changing my thoughts has allowed me to change my life."
              </p>
              <h4 className="name">Davide, London</h4>
              <p>on using meditation to turn his life around</p>
            </div>
          </div>
          <div className="slide">
            <div className="testimonial">
              <CardIcon />
              <p className="comment">
                "A happy workforce leads to a happy environment."
              </p>
              <h4 className="name">Jaime, Spain</h4>
              <p className="detail">
                on the benefits of meditation in the workplace
              </p>
            </div>
          </div>
        </Slider>
        <div style={{ textAlign: "center" }} className="mt-5">
          <button className="button-btn" onClick={previous}>
            <BackIcon />
          </button>
          <button className="button-btn" onClick={next}>
            <ForwardIcon />
          </button>
        </div>
      </div>
      {/* <div className="card-detail">
        {data.map((dt, index) => (
          <div className="slider-container">
            <Slider {...settings}>
              <div>
                <p className="mt-2 comment">{dt.text}</p>
                <p className="name mt-2">{dt.name}</p>
                <p className="detail mt-2">{dt.detail}</p>
              </div>
            </Slider>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export { Ourusers };

