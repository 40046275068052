import React from "react";
import CloserAdd from "../../assets/images/Home/Closer/closeradd.png";
const Closer = () => {
  return (
    <>
      <div className="closer-component">
        <div className="content d-flex">
          <h1>
            A Closer Look at <span className="span-kansensus">Rolidex</span>
          </h1>
          <h3>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque labore et dolore magna aliqua.
          </h3>
        </div>
        <div className="add-banner mt-5">
          <img src={CloserAdd} alt="" />
        </div>
      </div>
    </>
  );
};
export { Closer };
