import React from "react";
import { Col, Row } from "react-bootstrap";

import FeatureImage from "../../assets/images/Home/Features/leftaddimage.png";

const Feature = () => {
  const features = [
    {
      title: "Post Own Favourite Pictures",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.",
      id: 1,
    },
    {
      title: "Buy Any Cars Online",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.",
      id: 2,
    },
    {
      title: "Become A Vendor Easily",
      description:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore.",
      id: 3,
    },
  ];
  return (
    <>
      <div className="features-component container" id="feature">
        <Row>
          <Col className="feature-image mt-5" xs={12} md={12} lg={6}>
            <img src={FeatureImage} alt="" />
          </Col>
          <Col className="feature-details mt-5" xs={12} md={12} lg={6}>
            <div className="content mt-1">
              <h5>Features</h5>
              <h1>
                Your Hub for Community <span>Voting</span>
              </h1>
              <div className="feature-list mt-5">
                {features.map((feature, index) => (
                  <div key={feature.id} className="feature-item mb-3">
                    <input
                      type="radio"
                      id={`feature${index}`}
                      name="feature"
                      defaultChecked={index === 1}
                    />
                    <label htmlFor={`feature${index}`}>
                      <h3>{feature.title}</h3>
                      <p className="mb-2">{feature.description}</p>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};
export { Feature };
